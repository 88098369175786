<template>
  <k-field-group language-prefix="client.fields">
    <v-subheader class="pa-0">{{ $t('client.anonymize') }}</v-subheader>
    <v-card v-if="values">
      <v-card-text>
        <KCheckbox grid="col-3" v-model="values.canAnonymize" field="canAnonymize" @change="handleTouchAnonymize"/>
        <v-row class="no-gutters">
          <KTextField :disabled="!values.canAnonymize" v-model="values.anonymizeAfterYears" field="anonymizeAfterYears" grid="col-3" @focus="handleTouchAnonymize" />
          <KTextField :disabled="!values.canAnonymize" v-model="values.anonymizeAfterMonths" field="anonymizeAfterMonths" grid="ml-3 col-3"  @focus="handleTouchAnonymize"/>
        </v-row>
        <KCheckbox grid="col-3" v-model="values.canDelete" field="canDelete" @change="handleTouchAnonymize"/>
        <v-row class="no-gutters">
          <KTextField :disabled="!values.canDelete" v-model="values.deleteAfterYears" field="deleteAfterYears" grid="col-3" @focus="handleTouchAnonymize"/>
          <KTextField :disabled="!values.canDelete" v-model="values.deleteAfterMonths" field="deleteAfterMonths" grid="ml-3 col-3" @focus="handleTouchAnonymize"/>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end" v-if="can('client.anonymize.all')">
        <v-btn @click="handleSave" color="primary" :loading="isLoading">{{ $t("actions.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </k-field-group>
</template>

<script>
import Vue from 'vue';
import KCheckbox from "@/components/crud/fields/KCheckbox.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import EventBus from '@/eventBus.js';
import { show, update } from "@/api/endpoints/clientAnonymize.js";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import ClientAnonymize from "@/application/models/clientAnonymize.js";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: 'ClientAnonymize',
  components: { KFieldGroup, KTextField, KCheckbox },
  data: () => ({
    focusTimeout: 0,
    dontShowConfirm: false,
    values: new ClientAnonymize(),
    isLoading: false,
  }),
  async created() {
    const response = await show(this.$route.params.clientId);
    this.values = new ClientAnonymize().mapResponse(response.data.data);
    this.values.id = this.$route.params.clientId;
  },
  computed: {
    ...mapGetters("profile", ["can"]),
  },
  methods: {
    async handleSave() {
      try {
        this.isLoading = true;
        await update(this.values);
        EventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t("global.saveSuccess"),
        });
      } catch (error) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t("errors.save"),
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleTouchAnonymize() {
      clearTimeout(this.focusTimeout);
      if (this.dontShowConfirm) {
        return;
      }
      this.focusTimeout = setTimeout(() => {
        EventBus.$emit('confirm', {
          title: this.$t('client.confirmationDialogAnonymize.title'),
          body: this.$t('client.confirmationDialogAnonymize.body'),
          confirmCallback: () => {
            this.dontShowConfirm = true;
          },
          showCancel: false
        });
      }, 300);
    },
  }
});
</script>
