import Model from './model.js';

class ClientAnonymize extends Model {
  id = 0;
  canAnonymize = false;
  anonymizeAfterYears = 0;
  anonymizeAfterMonths = 0;
  canDelete = false;
  deleteAfterYears = 0;
  deleteAfterMonths = 0;

  mapForRequest() {
    return {
      canAnonymize: this.canAnonymize,
      anonymizeAfterYears: this.anonymizeAfterYears,
      anonymizeAfterMonths: this.anonymizeAfterMonths,
      canDelete: this.canDelete,
      deleteAfterYears: this.deleteAfterYears,
      deleteAfterMonths: this.deleteAfterMonths,
    };
  }
}

export default ClientAnonymize;
